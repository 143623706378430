@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700');

:root {
  --e-global-color-primary: #0C233F; 
  --e-global-color-accent: #E74C38;
  --e-global-color-7fdf111d: #FFFFFF;
  --e-global-color-text: #444444;
  --e-global-color-2ea76857: #7A7A7A;
}
.App {
  text-align: center;
  font-family: "Montserrat" , Sans-serif;
}

.App-loading {
  overflow: hidden;
  height: 100vh;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

h1, h2, h5, h6 {
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: .9rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

.title {
  font-family: "Montserrat", Sans-serif;
  line-height: 1.2em !important;
}

.elementor-container {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  max-width: 1140px;
}

.elementor-widget:not(:last-child) {
  margin-bottom: 5px;
}

.elementor-widget-wrap {
  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
}

.elementor-widget-wrap > .elementor-element {
  width: 100%;
}

.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
  display: flex;
}

.elementor-widget-heading .elementor-heading-title {
  color: var( --e-global-color-primary );
}

.elementor-widget-image img {
  vertical-align: middle;
  display: inline-block;
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.elementor-widget-text-editor {
  color: var( --e-global-color-text );
}


.elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
}

.elementor-row {
  width: 100%;
  display: flex;
}

.elementor-column {
  position: relative;
  min-height: 1px;
  display: flex;
}

.elementor-column-wrap {
  width: 100%;
  position: relative;
  display: flex;
}

@media (max-width: 1024px) {
  .elementor-section .elementor-container {
    flex-wrap: wrap;
  }

  .elementor-container {
    max-width: 1024px;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  .elementor-column.elementor-col-33 {
    width: 100%;
  }

  .elementor-container {
    max-width: 767px;
    flex-direction: column;
  }

  .elementor-column {
    width: 100%;
  }

  .elementor-row {
    flex-direction: column;
  }
}

