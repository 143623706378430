.button-container {
    padding: 12px 0px 12px 0px;
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
}

.discount-container {
    background-color: var( --e-global-color-primary );   
    width: 100%;
    text-align: center;
    padding: 12px 10px 10px 10px;
}

.discount-container h6 {
    color: var(--e-global-color-7fdf111d);
    font-family: "Montserrat", Sans-serif;
    padding: 0;
    margin: 0;
    line-height: 1;
    font-size: 1rem;
    font-weight: 500;
}