.elementor-column-wrap {
    flex-direction: column;
}

.title-wrapper-2 {
    padding: 60px 0px 65px 0px;
}

.circle-section {
    padding: 0px 0px 34px 0px;
    width: 100%;
}

.elementor-image {
    margin-bottom: 5px;
    padding: 20px 20px 20px 20px;

}

.circle-image {
    border-radius: 0px 0px 0px 0px;
    vertical-align: middle;
    display: inline-block;
    height: auto;
    max-width: 100%;
    border: none;
    box-shadow: none;
    border-radius: 50%;    
}

.elementor-text {
    text-align: center;
    font-family: "Montserrat", Sans-serif;
    font-size: 21px;
    line-height: 1.5em;
    margin: 10px 30px 20px 30px;
    color: var( --e-global-color-text );
}

@media (max-width: 767px) {
    .elementor-container {
      max-width: 767px;
    }

    .title-wrapper-2 {
        padding: 50px 0px 55px 0px;
    }
}