.one-step-closer-section {
    padding: 25px 0px 70px 0px;
}

.title-wrapper-5 {
    padding: 60px 0px 53px 0px;
}

.content-section {
    display: flex;
    padding: 20px 0px 70px 0px;
    width: 100%;
}

.image-wrapper-3 {
    padding: 10px;
    display: flex;
    margin: 0px -60px 0px 60px;
}

.rectangular-image {
    border-radius: 20px;
    height: auto;
    max-width: 100%;
    border: none;
    box-shadow: none;
}

.text-content {
    position: relative;
    z-index: 1;
}

.text-container-1 {
    background-color: #F3F3F3;
    margin: 60px 60px 60px -60px;
    border-radius: 20px 20px 20px 20px;
    padding: 10px 0px 0px 0px;
    display: flex;
    flex-direction: column;
}

.title-wrapper-6 {
    margin: 50px 50px 20px 60px;
    padding: 0px 60px 0px 0px;
    text-align: left;
}

.title-wrapper-6 h5 {
    color: var( --e-global-color-primary );
    line-height: 1.4em !important;
    font-weight: 600;
}

.text-wrapper-3 {
    margin: 0px 0px 0px 42px;
    padding: 0px 20px 50px 20px;
    font-family: "Montserrat", Sans-serif;
    line-height: 1.4em;
    color: var( --e-global-color-text );
    text-align: left;
}

.button-container-2 {
    width: 100%;
}

@media (max-width: 767px) { 
    .content-section {
        flex-direction: column;
    }

    .image-wrapper-3 {
        padding: 0;
        margin: 0;
    }

    .image-container-3 {
        width: 100%;
    }

    .text-content {
        width: 100%;
    }

    .text-container-1 {
        padding: 0;
        margin: 0;
    }

    .title-wrapper-6 {
        margin: 30px 30px 30px 30px;
        padding: 0;
    }

    .text-wrapper-3 {
        margin: 30px 30px 30px 30px;
        padding: 0;
    }
}

@media (min-width: 768px) { 
    .image-container-3 {
        width: 58.707%;
    }

    .text-content {
        width: 41.25%;
    }
}
