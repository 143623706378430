.footer-section {
    background-color: #112947;
    padding: 60px 0px 30px 0px;
}
  
.footer-container {
    margin: 30px;
}
  
.footer-img-container {
    text-align: center;
    margin-bottom: 5px;
}

.tos span a {
    color: #E74C38;
    text-decoration: none;
}
  
.separator {
    margin: 0 5px 0 5px;
}
  
.email a {
    color: #FFF;
    text-decoration: none;
    vertical-align: middle;
}
  
.mail-icon {
    margin-right: 5px;
    vertical-align: middle;
}

.footer-img {
    width: 80%;
}
  
.footer-tos-container {
    color: #FFF;
    font-size: 15px;
    font-weight: 450;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
}
  
.trademark-container,
.tos-container,
.email-container {
    width: 100%;
    padding: 10px;
    text-align: center;
}

@media (min-width: 768px) { 
    .footer-img {
        width: inherit;
    }

    .footer-tos-container {
        flex-direction: unset;
    }

    .trademark-container,
    .tos-container,
    .email-container {
        width: 33.33%;
    }

    .trademark-container {
        text-align: right;
    }

    .email-container {
        text-align: left;
    }
}