.what-you-get-section {
    background-color: #F3F3F3;
    padding: 45px 0px 80px 0px;
}

.title-wrapper-3 {
    padding: 41px 41px 41px 41px;
}

.card-container-1 {
    width: 55.179%;
    display: flex;
}

.card-1,
.card-2 {
    background-color: var( --e-global-color-7fdf111d );
    border-radius: 20px 20px 20px 20px;
    border-style: solid;
    border-width: 5px 5px 5px 5px;
    border-color: #E9E9E9;
}

.card-1 {
    margin: 45px 0px 145px 60px;
}

.card-content {
    padding: 60px 60px 60px 60px !important;
    text-align: left;
}

.title-wrapper-4 {
    padding: 0px 0px 15px 0px;
}

.card-title {
    color: #E74C38;
    font-weight: 500;
    line-height: 1.2em;
}

.text-wrapper-2 {
    font-family: "Montserrat", Sans-serif;
    line-height: 1.4em;
    color: var( --e-global-color-text );
}

.image-container-1 {
    width: 44.821%;
    display: flex;
}

.image-wrapper-1 {
    padding: 10px;
    margin: 0px 85px 0px -40px;
}

.image {
    vertical-align: middle;
    display: inline-block;
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 50%;
    box-shadow: none;    
    position: relative;
    z-index: 1000;
}

.image-container-2 {
    width: 41.02%;
    display: flex;
}

.image-wrapper-2 {
    padding: 10px;
    margin: 0px -37px 0px 60px;

}

.card-container-2 {
    width: 58.931%;
    display: flex;
}

.card-2 {
    margin: 45px 180px 59px -15px;
}

@media (max-width: 767px) {
    .card-container-1,
    .card-container-2,
    .image-container-1,
    .image-container-2 {
        width: 100%;
    }
    
    .image-container-1,
    .image-container-2 {
        padding: 0px 0px 50px 0px;
    }    

    .card-1,
    .card-2 {
        margin: 0;
    }

    .card-content {
        padding: 45px 45px 45px 45px !important;
    }

    .image-wrapper-1,
    .image-wrapper-2 {
        padding: 0;
        margin: 0;
    }
}