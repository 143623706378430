.button-wrapper {
    width: 100%;
    display: flex;    
    padding: 10px;
    justify-content: center;
}

.button {
    background-color: var(--e-global-color-accent);
    display: inline-block;
    line-height: 1;
    font-size: 15px;
    padding: 12px 24px;
    text-align: center;
    border-radius: 20px 20px 20px 20px;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    color: var( --e-global-color-7fdf111d );
    font-weight: 600;
    transition: all .3s;
}

.button:hover {
    color: var( --e-global-color-7fdf111d );
    background-color: var( --e-global-color-primary );
}

.button a {
    box-shadow: none;
    text-decoration: none;
    color: inherit;
}