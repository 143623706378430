.separator {
    background-color: #000;
    border: 0 none;
    height: 1px;
}

.separator-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 5px;
}

.title-wrapper-1 {
    padding: 70px 0px 80px 0px;
}

.card-wrapper {
    padding: 0px 0px 10px 0px;
}

.card-text-color-1 {
    background-color: transparent;
    background-image: linear-gradient(150deg, #D2E6FF 0%, #D1F0D5 100%);
}

.card-text-color-2 {
    background-color: transparent;
    background-image: linear-gradient(210deg, #FFFDBF 0%, #FFD3D3 100%);
}

.card-text-color-3 {
    background-color: transparent;
    background-image: linear-gradient(150deg, #F0DEFB 0%, #D0E5FD 100%);
}

.card-text-color-4 {
    background-color: transparent;
    background-image: linear-gradient(210deg, #C6FFCA 0%, #FFFDBF 100%);
}

.card-text-color-1,
.card-text-color-2,
.card-text-color-3,
.card-text-color-4 {
    padding: 30px 30px 30px 30px !important;
}

.card-text { 
    text-align: left;
}

.number-wrapper {
    padding: 35px 35px 0px 35px;
    margin-bottom: 5px;
    width: 100%;
}

.number {
    font-family: "Montserrat", Sans-serif;
    font-weight: bold;
}

.text-wrapper {
    padding: 35px 35px 35px 35px;
    margin-bottom: 5px;
}

.text {
    font-family: "Montserrat", Sans-serif;
    font-weight: 400;
    line-height: 1.4em !important;
}

.subtext-wrapper {
    padding: 0px 0px 0px 35px;
    color: var(--e-global-color-text);
    font-weight: 1.5;
    font-weight: 400;
}

.button-container-1 {
    padding: 75px 55px 90px 55px;
}

@media (min-width: 768px) { 
    .card-image {
        width: 40%;
    }

    .card-text {
        width: 60%;
    }
}

@media (max-width: 767px) { 
    .button-container-1 {
        padding: 75px 0 90px 0;
    }
}