.image-section {
    background-image: url("https://wellworld.io/wp-content/uploads/2022/10/dfh.detoxkit.counter.jpg");
    background-position: center center;
    background-size: cover;
}

.image-container-4 {
    padding: 45px 45px 45px 45px !important;
}

.title-wrapper-7 {
    margin: 0px 190px 0px 0px;
    padding: 25px 0px 11px 0px;
    text-align: left;
}

.title-wrapper-7 h1 {
    line-height: 1.4em;
}

.blank-1 {
    margin: 0% 8% 0% 0%;
    padding: 0px 0px 190px 0px;
}

.text-section {
    padding: 30px 30px 183px 30px;
    height: 1px;
}

.text-section > .elementor-container {
    height: 1px;
}

.blank-2 {
    width: 59.124%;
}

.text-container-2 {
    width: 40.828%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.text-wrapper-4 {
    position: absolute;
    left: 0;
    bottom: 0;
    text-align: center;
    color: var( --e-global-color-primary );
    font-size: 19px;
    width: 100%;
    max-width: 100%;
    padding: 0px 0px 15px 5px;
    font-weight: 600;
    line-height: 1.5;
}

.button-container-3 {
    display: flex;
    width: 100%;
}

.button-container-3 > .button-wrapper {
    padding: 0;
}

.elementor-image-1 img {
    height: auto;
    max-width: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    vertical-align: middle;
    display: inline-block;
}

@media (max-width: 768px) { 
    .image-section {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 0px 0px 0px 0px;
    }

    .image-container-4 {
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px !important;
    }
}

@media (max-width: 767px) { 
    .image-section {
        background-image: none;
    }

    .title-wrapper-7 {
        margin: 0;
        text-align: center;
        padding: 0px 0px 28px 0px;
    }

    .image-container-4 {
        margin: 0px 0px 0px 0px;
        padding: 45px 45px 45px 45px !important;
    }

    .text-wrapper-4 {
        position: relative;
        padding: 0px 0px 27px 0px;
    }

    .text-container-2 {
        width: 100%;
    }
}

